<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑" : "新增" }}</div>
      <div>
        <a-form-model ref="form" :model="form.others" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="name" label="食品生产者名称">
            <a-input v-model="form.others.name" />
          </a-form-model-item>
          <a-form-model-item prop="number" label="许可证号码">
            <a-input v-model="form.others.number" />
          </a-form-model-item>
          <a-form-model-item prop="manager" label="召回事务负责人">
            <a-input v-model="form.others.manager" />
          </a-form-model-item>
          <a-form-model-item prop="legal" label="法定代表人">
            <a-input v-model="form.others.legal" />
          </a-form-model-item>
          <a-form-model-item prop="fax" label="传真">
            <a-input v-model="form.others.fax" />
          </a-form-model-item>
          <a-form-model-item prop="product_name" label="品名">
            <a-input v-model="form.others.product_name" />
          </a-form-model-item>
          <a-form-model-item prop="category" label="食品类别">
            <a-input v-model="form.others.category" />
          </a-form-model-item>
          <a-form-model-item prop="handle_time" label="生产日期">
            <a-date-picker v-model="form.others.handle_time" valueFormat="YYYY-MM-DD" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="batch" label="产品批次">
            <a-input v-model="form.others.batch" />
          </a-form-model-item>
          <a-form-model-item prop="sales_quantity" label="销售数量">
            <a-input v-model="form.others.sales_quantity" />
          </a-form-model-item>
          <a-form-model-item prop="trademark" label="商标">
            <a-input v-model="form.others.trademark" />
          </a-form-model-item>
          <a-form-model-item prop="specifications" label="规格">
            <a-input v-model="form.others.specifications" />
          </a-form-model-item>
          <a-form-model-item prop="period" label="保质期（天）">
            <a-input v-model="form.others.period" />
          </a-form-model-item>
          <a-form-model-item prop="producer" label="产地">
            <a-input v-model="form.others.producer" />
          </a-form-model-item>
          <a-form-model-item prop="number1" label="召回数量">
            <a-input v-model="form.others.number1" />
          </a-form-model-item>
          <a-form-model-item prop="style" label="召回处置方式">
            <a-select v-model="form.others.style" style="width: 100%">
              <a-select-option value="无害化处理">
                无害化处理
              </a-select-option>
              <a-select-option value="就地销毁">
                就地销毁
              </a-select-option>
              <a-select-option value="集中销毁">
                集中销毁
              </a-select-option>
              <a-select-option value="标签标志改正">
                标签标志改正
              </a-select-option>
              <a-select-option value="其他">
                其他
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="number" label="召回处置时间">
            <a-date-picker v-model="form.others.handle_time1" valueFormat="YYYY-MM-DD" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="user" label="召回处置人员">
            <a-input v-model="form.others.user" />
          </a-form-model-item>
          <a-form-model-item prop="address1" label="召回处置地点">
            <a-input v-model="form.others.address1" />
          </a-form-model-item>
          <a-form-model-item prop="effect" label="召回处置效果">
            <a-input v-model="form.others.effect" />
          </a-form-model-item>
          <a-form-model-item prop="contact" label="联系人">
            <a-input v-model="form.others.contact" />
          </a-form-model-item>
          <a-form-model-item prop="harmless" label="无害化处理情况">
            <a-textarea v-model="form.others.harmless" />
          </a-form-model-item>
          <a-form-model-item prop="location" label="住所">
            <a-input v-model="form.others.location" />
          </a-form-model-item>
          <a-form-model-item prop="personnel" label="处理人员">
            <a-input v-model="form.others.personnel" />
          </a-form-model-item>
          <a-form-model-item prop="handle_style" label="处理方式">
            <a-input v-model="form.others.handle_style" />
          </a-form-model-item>
          <a-form-model-item prop="phone" label="手机号">
            <a-input v-model="form.others.phone" />
          </a-form-model-item>
          <a-form-model-item prop="email" label="邮箱">
            <a-input v-model="form.others.email" />
          </a-form-model-item>
          <a-form-model-item prop="address" label="地址">
            <a-input v-model="form.others.address" />
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-input v-model="form.others.remark" allowClear />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { callHandleAdd, callHandleEdit } from "@/api/refrigerate";

export default {
  name: "FormModal",
  props: ["visible", "form", "clientsClassificationOptions"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      levelOptions: [
        { id: "0", name: "0" },
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "3", name: "3" },
      ],
      rules: {
        name: [
          { required: true, message: "请输入食品生产者名称", trigger: "blur" },
          { max: 64, message: "超出最大长度 (64)", trigger: "blur" },
        ],
        number: [
          { required: true, message: "请输入许可证号码", trigger: "blur" },
          { max: 32, message: "超出最大长度 (32)", trigger: "blur" },
        ]
      },
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? callHandleEdit : callHandleAdd;
          func(this.form)
            .then(() => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create");
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      if(!this.form.id) {
        this.$refs.form.resetFields();
      }
    },
  },
};
</script>

<style scoped></style>
